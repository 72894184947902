'use client'

import {
  ComponentProps,
  ElementType,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'

import { IconButton } from '@syconium/little-miss-figgy/dist/components/IconButton/IconButton'
import { RotateClockwise } from '@syconium/little-miss-figgy/dist/components/Icons/BaseIcon'
import { Carat } from '@syconium/little-miss-figgy/dist/components/Icons/Icon/Carat'
import { ScreenReaderOnly } from '@syconium/little-miss-figgy/dist/components/ScreenReaderOnly/index'
import { untilMd } from '@syconium/little-miss-figgy/dist/constants/breakpoints'
import { fromMd } from '@syconium/little-miss-figgy/dist/constants/breakpoints'
import { useSupportsHover } from '@syconium/little-miss-figgy/dist/lib/hooks/useSupportsHover'

export type TilesLayoutContextValue = {
  visibleTiles: {
    sm: number
    md: number
  }
  layout: {
    sm: 'grid' | 'slider'
    md: 'grid' | 'slider'
  }
  gutters: {
    sm: number
    md: number
  }
}

const TilesLayoutContext = createContext<TilesLayoutContextValue | undefined>(undefined)

export const useTilesLayoutContext = () => {
  return useContext<TilesLayoutContextValue | undefined>(TilesLayoutContext)
}

const StyledTilesLayoutWrapper = styled.div`
  position: relative;
  display: block;
  max-width: 100%;
  z-index: 0;
`

const gapSpacingUnits = 4

const StyledTilesLayout = styled.div<{
  $layout: TilesLayoutContextValue['layout']
  $visibleTiles: TilesLayoutContextValue['visibleTiles']
  $gutters: TilesLayoutContextValue['gutters']
}>`
  width: 100%;
  max-width: 100%;
  outline: none;
  overflow-y: hidden;
  overflow-x: scroll;
  scroll-behavior: smooth;
  overscroll-behavior-x: contain;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  flex-shrink: 0;
  flex-grow: 1;
  display: inline-flex;
  gap: ${o => o.theme.spacing(gapSpacingUnits)};
  z-index: 0;
  box-sizing: border-box;

  ${o =>
    o.$layout.sm === 'grid'
      ? `
        ${untilMd} {
          padding-left: ${o.$gutters.sm}px;
          padding-right: ${o.$gutters.sm}px;
          display: grid;
          grid-template-columns: repeat(${o.$visibleTiles.sm}, minmax(0, 1fr));
        }
      `
      : null}

  ${o =>
    o.$layout.md === 'grid'
      ? `
        ${fromMd} {
          display: grid;
          grid-template-columns: repeat(${o.$visibleTiles.md}, minmax(0, 1fr));
          padding-left: ${o.$gutters.md}px;
          padding-right: ${o.$gutters.md}px;
        }
      `
      : null}
`

const StyledSliderButtonWithoutContext = styled(IconButton)<{
  $lifted: boolean
  $direction: 'back' | 'forward'
  $gutters?: TilesLayoutContextValue['gutters']
}>`
  position: absolute;
  z-index: ${o => o.theme.zIndex.sticky - 1};

  top: ${props => (props.$lifted ? 'calc(50% - 24px)' : '50%')};
  ${untilMd} {
    ${o => (o.$direction === 'back' ? 'left' : 'right')}: ${o => o.$gutters?.sm ?? 0}px;
  }
  ${fromMd} {
    ${o => (o.$direction === 'back' ? 'left' : 'right')}: ${o => o.$gutters?.md ?? 0}px;
  }
`

const StyledSliderButton = ({
  variant = 'black-on-white-no-border',
  $gutters: $guttersProp,
  ...rest
}: ComponentProps<typeof StyledSliderButtonWithoutContext>) => {
  const tilesLayoutContext = useTilesLayoutContext()
  const $gutters = $guttersProp ?? tilesLayoutContext?.gutters
  return <StyledSliderButtonWithoutContext variant={variant} $gutters={$gutters} {...rest} />
}

const StyledGutterPlaceholder = styled.div<{
  $layout: TilesLayoutContextValue['layout']
  $gutters: TilesLayoutContextValue['gutters']
}>`
  display: inline-block;
  flex-shrink: 0;
  flex-grow: 0;

  ${untilMd} {
    ${o =>
      o.$layout.sm === 'grid'
        ? `
        display: none;
      `
        : null}

    flex-basis: ${o => o.$gutters.sm}px;
  }

  ${fromMd} {
    ${o =>
      o.$layout.md === 'grid'
        ? `
        display: none;
      `
        : null}

    flex-basis: ${o => o.$gutters.md}px;
  }

  &:first-child {
    margin-right: -${o => o.theme.spacing(gapSpacingUnits)};
  }

  &:last-child {
    margin-left: -${o => o.theme.spacing(gapSpacingUnits)};
  }
`

type TilesSliderProps = {
  className?: string
  children?: React.ReactNode
  scrollBackLabel: string
  scrollForwardLabel: string
  liftedButtons?: boolean
  layout: TilesLayoutContextValue['layout']
  visibleTiles: TilesLayoutContextValue['visibleTiles']
  gutters: TilesLayoutContextValue['gutters']
}

export const TilesLayout = ({
  className,
  children,
  scrollBackLabel,
  scrollForwardLabel,
  liftedButtons,
  layout: layoutProp,
  visibleTiles: visibleTilesProp,
  gutters: guttersProp,
}: TilesSliderProps) => {
  const [scrollsLeft, setScrollsLeft] = useState(false)
  const [scrollsRight, setScrollsRight] = useState(false)
  const hoverSupported = useSupportsHover()
  const sliderRef = useRef<HTMLDivElement | null>(null)

  const context = useMemo(() => {
    return {
      layout: layoutProp,
      visibleTiles: visibleTilesProp,
      gutters: guttersProp,
    }
  }, [layoutProp, guttersProp, visibleTilesProp])

  const scrollRight = useCallback(() => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft += sliderRef.current.getBoundingClientRect().width
    }
  }, [])

  const scrollLeft = useCallback(() => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft -= sliderRef.current.getBoundingClientRect().width
    }
  }, [])

  const checkIfScrollable = useCallback(() => {
    if (sliderRef.current && sliderRef.current.scrollWidth > sliderRef.current.clientWidth) {
      setScrollsLeft(sliderRef.current.scrollLeft > 8)
      setScrollsRight(
        sliderRef.current.scrollLeft + sliderRef.current.clientWidth + 8 <
          sliderRef.current.scrollWidth
      )
    } else {
      setScrollsLeft(false)
      setScrollsRight(false)
    }
  }, [])

  useEffect(() => {
    checkIfScrollable()

    const observer = new MutationObserver((mutationList, _observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          checkIfScrollable()
        }
      }
    })

    const targetNode = sliderRef.current
    window?.addEventListener('resize', checkIfScrollable, { passive: true })
    if (targetNode) {
      observer.observe(targetNode, { childList: true, subtree: true })
    }

    return () => {
      window?.removeEventListener('resize', checkIfScrollable)
      observer.disconnect()
    }
  }, [checkIfScrollable])

  return (
    <TilesLayoutContext.Provider value={context}>
      <StyledTilesLayoutWrapper>
        {hoverSupported && scrollsLeft ? (
          <StyledSliderButton
            onClick={scrollLeft}
            $lifted={liftedButtons ?? false}
            $direction='back'
          >
            <ScreenReaderOnly>{scrollBackLabel}</ScreenReaderOnly>
            <Carat
              aria-hidden={true}
              height='18'
              rotateClockwise={RotateClockwise.Half}
              width='16'
            />
          </StyledSliderButton>
        ) : null}
        <StyledTilesLayout
          className={className}
          ref={sliderRef}
          onScroll={checkIfScrollable}
          $layout={context.layout}
          $visibleTiles={context.visibleTiles}
          $gutters={context.gutters}
        >
          <StyledGutterPlaceholder $layout={context.layout} $gutters={context.gutters} />
          {children}
          <StyledGutterPlaceholder $layout={context.layout} $gutters={context.gutters} />
        </StyledTilesLayout>
        {hoverSupported && scrollsRight ? (
          <StyledSliderButton
            onClick={scrollRight}
            $lifted={liftedButtons ?? false}
            $direction='forward'
          >
            <ScreenReaderOnly>{scrollForwardLabel}</ScreenReaderOnly>
            <Carat aria-hidden={true} height='18' width='16' />
          </StyledSliderButton>
        ) : null}
      </StyledTilesLayoutWrapper>
    </TilesLayoutContext.Provider>
  )
}

type StyledTileProps = {
  $layout?: TilesLayoutContextValue['layout']
  $visibleTiles?: TilesLayoutContextValue['visibleTiles']
  $gutters?: TilesLayoutContextValue['gutters']
}

const portionOfAdditionalTileOverlappingViewportSm = 32
const portionOfAdditionalTileOverlappingViewportMd = 172

const StyledTile = styled.div<StyledTileProps>`
  flex-grow: 0;
  flex-shrink: 0;

  ${o =>
    o.$visibleTiles?.sm && o.$layout?.sm !== 'grid'
      ? `
      ${untilMd} {
        width: 50vw;

        flex-basis: calc(
          ${100 / o.$visibleTiles.sm}% - ${o.theme.spacing(gapSpacingUnits)} - ${
            portionOfAdditionalTileOverlappingViewportSm / o.$visibleTiles.sm
          }px - ${o.$gutters?.sm && o.$gutters.sm > 0 ? o.$gutters.sm / o.$visibleTiles.sm : 0}px
        );
      }
    `
      : null}

  ${o =>
    o.$visibleTiles?.md && o.$layout?.md !== 'grid'
      ? `
          ${fromMd} {
            width: 50vw;

            flex-basis: calc(
              ${100 / o.$visibleTiles.md}% - ${o.theme.spacing(gapSpacingUnits)} - ${
                portionOfAdditionalTileOverlappingViewportMd / o.$visibleTiles.md
              }px - ${
                o.$gutters?.md && o.$gutters.md > 0 ? o.$gutters.md / o.$visibleTiles.md : 0
              }px
            );
          }
        `
      : null}
`

type TileProps = {
  visibleTiles?: TilesLayoutContextValue['visibleTiles']
  as?: ElementType
} & Omit<ComponentProps<typeof StyledTile>, keyof StyledTileProps>

export const Tile = ({ visibleTiles, ...props }: TileProps) => {
  const tilesLayoutContext = useTilesLayoutContext()
  return (
    <StyledTile
      $visibleTiles={visibleTiles ?? tilesLayoutContext?.visibleTiles}
      $gutters={tilesLayoutContext?.gutters}
      $layout={tilesLayoutContext?.layout}
      {...props}
    />
  )
}
