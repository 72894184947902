import { useCallback, useMemo } from 'react'

import {
  SupportedLanguageGroupIdentifier,
  SupportedRegionIdentifier,
  supportedRegions,
} from '@syconium/weeping-figs'

import { useLocalization } from '../../app/_providers/LocalizationProvider.client'
import { useTranslation } from '../../app/_providers/TranslationProvider.client'
import { usePurePathname } from '../../lib/hooks/usePurePathname'

type HreflangLink = {
  rel: 'alternate'
  hreflang: `${SupportedLanguageGroupIdentifier}-${SupportedRegionIdentifier}` | 'x-default'
  href: string
}

export function useLocalizedMeta() {
  const {
    locale: selectedLocale,
    region: selectedRegion,
    languageGroup: selectedLanguage,
    requestIncludedLocalizationPathParam,
  } = useLocalization()
  const pathname = usePurePathname()
  const rel = 'alternate'

  const {
    globals: {
      canonicalBaseUrl: { home },
    },
  } = useTranslation()
  const homeNoTrailingSlash = home.replace(/\/$/, '') // remove trailing slash

  const getAlternateLinks = useCallback(
    (canonicalUrl?: string) => {
      const path = canonicalUrl?.split(homeNoTrailingSlash)[1] ?? pathname
      const pathNoTrailingSlash = path.replace(/\/$/, '')
      const links: HreflangLink[] = [
        {
          rel: rel,
          hreflang: 'x-default',
          href: `${homeNoTrailingSlash}${path}`,
        },
      ]

      Object.entries(supportedRegions).forEach(([_regionCode, region]) => {
        if (region.isEnabled || region.id === selectedRegion.id) {
          Object.entries(region.languages).forEach(([languageGroupAsString, regionalLanguage]) => {
            if (
              regionalLanguage.isEnabled ||
              (languageGroupAsString === selectedLanguage && region.id === selectedRegion.id)
            ) {
              const languageGroup =
                languageGroupAsString as unknown as keyof typeof region.languages
              const languageRegionCombo: `${SupportedLanguageGroupIdentifier}-${SupportedRegionIdentifier}` = `${languageGroup}-${region.id}`
              const href = `${home}${languageRegionCombo}${pathNoTrailingSlash}` // don't use slash for int'l
              links.push({
                rel: rel,
                hreflang: languageRegionCombo,
                href: href,
              })
            }
          })
        }
      })

      return links
    },
    [pathname, home, homeNoTrailingSlash, selectedLanguage, selectedRegion.id]
  )

  const getLocalizedCanonicalUrl = useCallback(
    (canonicalUrl: string | undefined) => {
      if (canonicalUrl && !requestIncludedLocalizationPathParam) {
        return canonicalUrl
      }

      const pathNoTrailingSlash = (canonicalUrl?.split(homeNoTrailingSlash)[1] ?? pathname).replace(
        /\/$/,
        ''
      )

      if (!requestIncludedLocalizationPathParam) {
        return `${homeNoTrailingSlash}${pathNoTrailingSlash}`
      } else {
        return `${home}${selectedLanguage}-${selectedRegion.id}${pathNoTrailingSlash}`
      }
    },
    [
      requestIncludedLocalizationPathParam,
      homeNoTrailingSlash,
      pathname,
      home,
      selectedLanguage,
      selectedRegion.id,
    ]
  )

  const formattedLocale = useMemo(() => {
    return selectedLocale.replace('-', '_')
  }, [selectedLocale])

  return {
    getAlternateLinks,
    getLocalizedCanonicalUrl,
    formattedLocale,
  }
}
