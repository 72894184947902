import Head from 'next/head'

import { Button } from '@syconium/little-miss-figgy'
import { useLocalizedMeta } from '@syconium/magnolia/src/components/SeoMeta/useLocalizedMeta'

import { NextLink } from '../../../../app/_components/navigation/NextLink'
import {
  PageSection,
  PageSectionContent,
} from '../../../../app/_components/pages/PageSection.client'

import { Container, Heading, LinksWrapper, ResponsiveImage, SubHeading } from './styles'

type ErrorViewHeadProps = {
  canonicalUrl: string
}

export const ErrorViewHead = ({ canonicalUrl }: ErrorViewHeadProps) => {
  const { getAlternateLinks, getLocalizedCanonicalUrl } = useLocalizedMeta()
  return (
    <Head>
      <meta name='robots' content='noindex' key='robots' />
      <link rel='canonical' href={getLocalizedCanonicalUrl(canonicalUrl)} key='canonical' />
      {getAlternateLinks(canonicalUrl).map((item, index) => {
        return (
          <link
            rel={item.rel}
            hrefLang={item.hreflang}
            href={item.href}
            key={`alternate-${index}`}
          />
        )
      })}
    </Head>
  )
}

type ErrorViewProps = {
  heading: string
  imageAlt: string
  imageSrc: string
  links: Array<{ href: string; title: string }>
  subheading: string
  children?: React.ReactNode
}

export const ErrorViewSection = ({
  children,
  imageAlt,
  imageSrc,
  links,
  subheading,
  heading,
}: ErrorViewProps) => {
  return (
    <PageSection>
      <PageSectionContent gutterSize='default'>
        <Container data-testid='not-found-page'>
          <ResponsiveImage
            src={imageSrc}
            alt={imageAlt}
            aspectRatios={{
              sm: null,
              md: null,
            }}
            loading='default'
            widths={{ unit: 'vw', sm: 100, md: 50 }}
          />
          <Heading asTag='h1'>{heading}</Heading>

          <SubHeading>{subheading}</SubHeading>

          <LinksWrapper>
            {links.map((link, index) => (
              <Button as={NextLink} href={link.href} key={index}>
                {link.title}
              </Button>
            ))}
          </LinksWrapper>

          {children}
        </Container>
      </PageSectionContent>
    </PageSection>
  )
}
